<template>
  <div class="vertical-menu">
    <div class="navbar-brand-box">
      <a href="/dashboard" class="logo logo-dark">
        <span class="logo-sm">
          <img src="/img/logo.png" alt="" height="22" />
        </span>
        <span class="logo-lg">
          <img src="/img/logo.png" alt="" style="width: 80%; height: auto" />
        </span>
      </a>
      <a href="/dashboard" class="logo logo-light">
        <span class="logo-lg">
          <img
            src="/img/logo-dark.png"
            alt=""
            style="width: 80%; height: auto"
          />
        </span>
        <span class="logo-sm">
          <img src="/img/logo-dark.png" alt="" height="22" />
        </span>
      </a>
    </div>

    <button
      type="button"
      class="btn btn-sm px-3 font-size-26 header-item vertical-menu-btn"
    >
      <i class="uil fa-fw uil-bars"></i>
    </button>

    <div data-simplebar="init" class="sidebar-menu-scroll">
      <div class="simplebar-wrapper" style="margin: 0px">
        <div class="simplebar-height-auto-observer-wrapper">
          <div class="simplebar-height-auto-observer"></div>
        </div>
        <div class="simplebar-mask">
          <div class="simplebar-offset" style="right: -17px; bottom: 0px">
            <div
              class="simplebar-content-wrapper"
              style="height: 100%; overflow: hidden scroll"
            >
              <div class="simplebar-content" style="padding: 0px">
                <!--- Sidemenu -->
                <div id="sidebar-menu">
                  <!-- Left Menu Start -->
                  <ul
                    class="metismenu list-unstyled"
                    id="side-menu"
                    v-if="isReady"
                  >
                    <li class="menu-title" data-key="t-dashboards">
                      {{ $trans("Navigation") }}
                    </li>
                    <template v-for="(menu, i) in menus" :key="i">
                      <li v-if="!menu.submenus && canAccess(menu.title)">
                        <RouterLink :to="menu.url">
                          <i :class="menu.icon"></i>
                          <span class="menu-item text-capitalize">{{
                            $trans($helper.inputTitle(menu.title))
                          }}</span>
                          <span
                            class="badge rounded-pill badge-soft-secondary"
                            v-if="
                              menu.notifications &&
                              totalNotifications(menu.notifications)
                            "
                            >{{ totalNotifications(menu.notifications) }}</span
                          >
                        </RouterLink>
                      </li>
                      <li v-else-if="canAccess(menu.title)">
                        <a
                          href="javascript: void(0);"
                          class="has-arrow"
                          aria-expanded="false"
                          @click="showSubMenu($event)"
                        >
                          <i :class="menu.icon"></i>
                          <span class="menu-item text-capitalize"
                            >{{ $trans($helper.inputTitle(menu.title)) }}
                          </span>
                          <span
                            class="badge rounded-pill badge-outline-danger"
                            v-if="
                              menu.notifications &&
                              totalNotifications(menu.notifications)
                            "
                            >{{ totalNotifications(menu.notifications) }}</span
                          >
                        </a>
                        <ul class="sub-menu mm-collapse">
                          <template v-for="(item, i) in menu.submenus" :key="i">
                            <li v-if="canAccess(menu.title, i)">
                              <RouterLink :to="item" class="text-capitalize"
                                >{{ $trans($helper.inputTitle(i))
                                }}<span
                                  class="badge rounded-pill badge-outline-danger"
                                  v-if="item.notification"
                                  >{{ notifications[item.notification] }}</span
                                ></RouterLink
                              >
                            </li>
                          </template>
                        </ul>
                      </li>
                    </template>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { menus } from "./menus";
export default {
  name: "NavigationBar",
  data: () => ({
    menus,
    notifications: [],
  }),
  computed: {
    user() {
      return this.$store.state.user || {};
    },
    isReady() {
      return this.user && Object.keys(this.user).length > 0;
    },
    permissions() {
      return this.isReady && this.user.role.permissions;
    },
  },
  methods: {
    canAccess(menu, submenu = null) {
      if (!this.user.role_id) {
        return true;
      } else {
        if (!submenu) {
          return (
            this.permissions[menu] && this.permissions[menu].accessible == true
          );
        } else {
          return (
            this.permissions[menu][submenu] &&
            this.permissions[menu][submenu].includes("R")
          );
        }
      }
    },
    showSubMenu(event) {
      let a =
        event.target.tagName == "A" ? event.target : event.target.parentNode;
      let li = a.parentNode;
      li.classList.toggle("mm-active");
      a.classList.toggle("mm-collapsed");
      let ul = li.querySelector("ul");
      ul.classList.toggle("mm-show");
    },
    totalNotifications(keys) {
      return keys.reduce(
        (prev, next) => prev + Number(this.notifications[next]),
        0
      );
    },
  },
  mounted() {
    /**
     * Register all available menus and their object path string
     */
    let routes = {};
    menus.forEach((menu) => {
      if (!menu.submenus) {
        routes[menu.url.name] = menu.title;
      } else {
        for (let item in menu.submenus) {
          routes[menu.submenus[item].name] = `${menu.title}.${item}`;
        }
      }
    });
    this.$store.state.routes = routes;
    this.$store.commit("SET_AJAX_TASK", "GETTING_NOTIFICATIONS");
    this.$http.get("shared/notifications/badges").then((response) => {
      this.notifications = response.data.data;
    });
  },
};
</script>
<style scoped>
#sidebar-menu .has-arrow:after {
  content: "\eb3a";
  font-family: "unicons-line";
}
</style>
